import { PageProps, graphql } from "gatsby";
import React from "react";

import { BlocksContent } from "@components/global";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";
import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { Container } from "@util/standard";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

function TermsAndConditions({ data }: Props) {
  if (data == null || data.sanityTermsAndConditions == null) {
    return null;
  }

  const { seo, policyText, categoryPreview } = data.sanityTermsAndConditions;

  return (
    <div>
      <SEO seoData={seo} />
      <Layout>
        <Container
          width="65%"
          tabletWidth="80%"
          margin="220px auto 140px auto"
          tabletMargin="220px auto 40px auto"
          flexDirection="column"
        >
          {policyText && <BlocksContent blocks={policyText._rawColumnContent} />}
        </Container>
        {categoryPreview && <LearnCategoryPreview data={categoryPreview} />}
      </Layout>
    </div>
  );
}

export default TermsAndConditions;

export const query = graphql`
  {
    sanityTermsAndConditions {
      policyText {
        ...sanityColumn
      }
      seo {
        ...sanitySeo
      }
      categoryPreview {
        ...sanityLearnCategoryPreview
      }
    }
  }
`;
